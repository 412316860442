<template>
  <div class="reward-page">
    <h1>
      Welcome! <br>
      You were referred to be a first-time holders of the BAP NFT
    </h1>
    <div class="reward-page-items-wrapper">
      <ul class="reward-page-items">
        <li class="reward-page-item">
          <span class="reward-page-item-num">1</span>
          <span>
            Purchase NFT on
            <a
              target="_blank"
              href="https://opensea.io/collection/bullsandapes-genesis"
              class="reward-page-item-opensea-link"
            >OpenSea</a>
          </span>
        </li>
        <li class="reward-page-item">
          <span class="reward-page-item-num">2</span> Save the transaction hash
          of your order
        </li>
        <li class="reward-page-item">
          <span class="reward-page-item-num">3</span> Come back here to claim
          your METH reward. <br>
          <router-link :to="{ name: 'claimherdreward' }">
            Claim Reward
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
